const httpDelete = (url, csrf_token, callback, err = console.error) => {
  fetch(
    url,
    {
      method: 'DELETE',
      headers: {
        "X-CSRFToken": csrf_token,
      },

    }
  )
  .then(response => response.text())
  .then(response => callback(response))
  .catch(error => err(error));
};
window.httpDelete = httpDelete;

String.prototype.toSafeId = function () {
  return encodeURIComponent(this)
    .toLowerCase()
    .replace(/\.|%[0-9a-z]{2}/gi, '');
}
