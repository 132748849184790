import "../css/tabulator_simple.demin.css";

import "../css/app.css";

import "../css/notes.css";

import "flowbite";
import "flowbite/dist/datepicker";
import "./sidebar";
import "./dark-mode";
import "./sweetalert2";
import "./htmx/htmx.js";
import "./helper_utils.js";

// Check if user is hijacked and change navbar color
document.addEventListener("DOMContentLoaded", function () {
  const navbar = document.getElementById("main-navbar");
  const hijackNotification = document.getElementById("djhj");
  if (hijackNotification) {
    navbar.classList.add("navbar-hijacked");
    navbar.classList.remove("bg-white", "dark:bg-gray-800");
  }

  // Add global htmx:afterSettle event listener
  htmx.on("htmx:afterSettle", function (event) {
    if (typeof initFlowbite === "function") {
      initFlowbite();
    }
  });
});
